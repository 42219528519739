<template>
	<div class="Member bg-white">
		<Row class="px-1">
			<i-col span="24" class="align-justify">
				<Button type="default" icon="md-refresh" size="large" class="mr-1"></Button>
				<Button type="default" icon="md-add" size="large" class="mr-1"></Button>
				<i-input v-model="inputValue" size="large" style="width: 15rem" class="el-inline mr-1">
					<Select v-model="selectValue" slot="prepend" style="width: 5rem">
						<Option v-for="(item,key) in optionItems" :key="key" :value="item.value">{{item.label}}</Option>
					</Select>
					<Button slot="append" icon="md-search" style="width: 2.5rem"></Button>
				</i-input>
				<Button type="default" size="large" class="mr-1">批量移除</Button>
				<Button type="default" size="large" class="mr-1">批量导出</Button>
				<Button type="default" size="large" class="mr-1">批量导入</Button>
			</i-col>
			<i-col span="24">
				<Table border stripe context-menu show-context-menu ref="usersTable" :columns="tColumns" :data="tData" :height="theight" class="mt-1">
					<div slot="contextMenu">
						<DropdownItem @click.native="assessmentRecord">测评档案</DropdownItem>
						<DropdownItem @click.native="consultRecord">咨询档案</DropdownItem>
						<DropdownItem @click.native="assoUsers">关联用户</DropdownItem>
					</div>
					<div slot-scope="{ row, index }" slot="action">
						<Button type="primary" size="small" style="margin-right: 0.25rem" @click="show(index)">详情</Button>
						<Button size="small" @click="remove(index)">移除</Button>
					</div>
				</Table>
				<Page :total="recordTotal" :page-size="20" show-total show-elevator class="p-1 align-right" />
			</i-col>
		</Row>
	</div>
</template>

<script>
	import tData from '../json/member_tData.json'
	export default {
		name: 'Member',
		data() {
			return {
				tData,
				organ:{},
				inputValue: '',
				selectValue: 'usersName',
				theight: window.innerHeight - 275,
				recordTotal: 200,
				optionItems: [{
					"value": "usersId",
					"label": "用户号"
				}, {
					"value": "usersName",
					"label": "姓名"
				}, {
					"value": "phone",
					"label": "手机号"
				}],
				tColumns: [{
					type: 'selection',
					width: 35,
					align: 'center'
				},{
					type: 'index',
					title:'序号',
					width: 45,
					align: 'center'
				}, {
					title: '用户号',
					key: 'userId',
					minWidth: 100
				}, {
					title: '姓名',
					key: 'userName',
					minWidth: 100
				}, {
					title: '性别',
					key: 'gender',
					render:(h,params) =>{
						return h ('span',this.myjs.getGender(params.row.gender));
					},
					minWidth: 60
				}, {
					title: '年龄',
					key: 'age',
					render: (h, params) => {
						let age = this.myjs.getAge(params.row.birthday,new Date());
						return h('span', age)
					},
					minWidth: 70,
					sortable: true
				}, {
					title: '手机号',
					key: 'bindPhone',
					minWidth: 120
				}, {
					title: '婚姻',
					key: 'marriage',
					minWidth: 80
				}, {
					title: '学历',
					key: 'education',
					minWidth: 80,
					sortable: true
				}, {
					title: '部门',
					key: 'department',
					minWidth: 140,
					ellipsis: true
				}, {
					title: '注册时间',
					key: 'registerTime',
					minWidth: 100,
					render: (h, params) => {
						let dt = this.myjs.subDate(params.row.registerTime);
						return h('span', dt)
					},
					sortable: true,
					sortType: 'desc'
				}, {
					title: '操作',
					slot: 'action',
					width: 102,
				}]
			}
		},
		created() {
			this.organ = JSON.parse(sessionStorage.getItem('pageStr'));
			this.crumbstring([this.organ.unitName])
		},
		mounted() {
			window.addEventListener('resize', () => this.theight = window.innerHeight - 275, false)
			
		},
		methods: {
			assessmentRecord(){
				
			},
			consultRecord(){
				
			},
			assoUsers(){
				
			},
			crumbstring(arr) {
				let setValue = {
					lenCtrl: 3,
					addValue: arr
				};
				if(this.organ.unitId){
					this.$store.dispatch('setBreadcrumb', setValue)
				}else{
					this.$router.push({name: 'Organ'})
				}
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	
</style>
